import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"
import ImageFluid from "../misc/image-fluid"

const HeroSubpageBig = ({ intl, theme, content }) => (
    <div className={`hero-subpage-big ${theme}`}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
              <Row>
                <Col md={12}>
                  <ImageFluid alt={content.title} filename={content.image} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={`text-area`}>
                    <div className={`text`} dangerouslySetInnerHTML={{ __html: content.text }}></div>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
    </div>
)

export default injectIntl(HeroSubpageBig)
