import React from "react"
import {injectIntl} from "gatsby-plugin-react-intl"
import {Container} from "react-bootstrap";
import Seo from "../../../components/seo"
import HeroSubpageBig from "../../../components/heroes/hero-subpage-big"
import LayoutSecondary from "../../../components/layout-secondary";
import Story from "../../../components/misc/story";
import SliderBlocks from "../../../components/misc/slider-blocks";
import TrialForm from "../../../components/forms/trial-form";
import AnniversaryData from "../../../content/about/anniversary/index.json";
import SimpleCTA from "../../../components/ctas/simple-cta"
import ClientsLogos from "../../../components/misc/clients-logos"

const AnniversaryStoryPage = ({ intl, location }) => (
    <LayoutSecondary>
      <Seo
          lang={intl.locale}
          title={AnniversaryData[intl.locale].title}
          description={AnniversaryData[intl.locale].text}
          pathname={location.pathname}
      />
      <HeroSubpageBig
          theme={`secondary`}
          content={AnniversaryData[intl.locale]}
      />
      <div className={`anniversary`}>
        <div className={`top-overflow-wrapper`}>
          <Container>
            <div className={`trial-form-area`}>
              <div className={`trial`}>
                <div className={`trial-form-box`}>
                  <TrialForm content={AnniversaryData[intl.locale].trial} />
                </div>
              </div>
            </div>
            <Story content={AnniversaryData[intl.locale].story} />
          </Container>
          <ClientsLogos content={AnniversaryData[intl.locale].logos} />
          <Container>
            <SliderBlocks content={AnniversaryData[intl.locale].timeline} />
          </Container>
          <Container>
            <SimpleCTA theme={`secondary`} content={AnniversaryData[intl.locale].cta} />
          </Container>
        </div>
      </div>
    </LayoutSecondary>
)

export default injectIntl(AnniversaryStoryPage)
