import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {Container} from 'react-bootstrap';
import {injectIntl} from "gatsby-plugin-react-intl";
import Logo from "./logo"

const ClientsLogosBlock = () => (
    <StaticQuery
        query={graphql`
          query clientsLogosImagesQuery {
            source: allFile(
              filter: { 
                absolutePath: { regex: "/clients-logos/" } 
              }
            ) {
              edges {
                node {
                  name
                  relativePath
                }
              }
            }
          }
        `}
        render={data => {
            const shuffled = data.source.edges.sort(() => 0.5 - Math.random());
            let selected = shuffled.slice(0, 16);
            return (
                <div className={`logos`}>
                    {selected.map(({ node }, i) => {
                        return(
                          <Logo key={i} alt={``} filename={node.relativePath} />
                        )
                    })}
                </div>
            )
        }}
    />
);

// const ClientsLogos = ({intl, content}) => (
//
//     <div className={`clients-logos`}>
//         <Container>
//             {content.title &&
//             <div className={`title`}>{content.title}</div>
//             }
//             {content.subtitle &&
//             <div className={`subtitle`}>{content.subtitle}</div>
//             }
//             <div className={`logos-area`}>
//               <ClientsLogosBlock />
//             </div>
//         </Container>
//     </div>
//
// )

const ClientsLogos = ({intl, content}) => {
    const [ timer, setTimer ] = React.useState('');

    const changeLogos = () => {
      let timestamp = new Date().getTime();
      setTimer(timestamp);
      //Restart animation
      let logos = document.querySelectorAll(".logos-area > .logos > div");
      logos.forEach((logo) => {
        logo.style.animationName = 'none';
        requestAnimationFrame(() => {
          setTimeout(() => {
            logo.style.animationName = ''
          }, 0);
        });
      })
    }

    React.useEffect(() => {
      const timerID = setInterval(() => changeLogos(), 5000)
      return () => {
        clearInterval(timerID)
      }
    });

    return (
      <div className={`clients-logos`}>
        <Container>
          {content.title &&
            <div className={`title`}>{content.title}</div>
          }
          {content.subtitle &&
            <div className={`subtitle`}>{content.subtitle}</div>
          }
          <div className={`logos-area`}>
            <ClientsLogosBlock />
          </div>
        </Container>
      </div>
    )
}

export default injectIntl(ClientsLogos)
