import React from 'react';
import {injectIntl} from "gatsby-plugin-react-intl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const SliderBlocks = ({intl, content}) => {

    let  settings =  {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }

    return (

      <div className={`slider-blocks`}>
          {content.title &&
            <div className={`title`}>{content.title}</div>
          }
          <div className={`slider-area`}>
              <Slider {...settings}>
                  {content.blocks.map((item, i) => {

                      return (
                        <div key={i}>
                            <div className={`block`}>
                                <div className={`circle`}>{item.title}</div>
                                <div className={`text`}>{item.text}</div>
                            </div>
                        </div>
                      )

                  })}
              </Slider>
          </div>
      </div>

    )
}

export default injectIntl(SliderBlocks)
